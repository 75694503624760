import React, { useLayoutEffect } from "react";
import { useParams } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
import { useTracker } from "../utils/contexts/tracker-context";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function RoadhouseSplashPage(props) {
    const { trackDataFn } = useTracker();

    const { type, id: token } = useParams();
    const { t } = useTranslation();

    // need beacuse here I have no access to restaurant id
    let restaurantId = ""
    if (token === "l8FbIc0TG") {
        restaurantId = "23847"
    } if (token === "zyyC4vqpY") {
        restaurantId = "814"
    }


    useLayoutEffect(() => {
        async function track() {
            await trackDataFn(restaurantId, token, "usersession", { "type": type }, false);
        }

        track();
    }, []);

    async function trackPageViewHandler() {
        await trackDataFn(restaurantId, token, "pageview", { "type": type }, false);
    }

    return (
        <IonPage>
            <IonContent>
                <div className="h-screen bg-cover bg-center" style={{ backgroundImage: 'url("/assets/roadhouse/home.png")' }}>
                    <div className="absolute transform top-[54%] left-1/2 -translate-x-1/2 translate-y-1/2">
                        <Link onClick={trackPageViewHandler} to={`/r/q/${token}/menu`} className="block w-96 px-3 py-2 text-center font-bold text-white uppercase bg-[#BA0B31] rounded-full">{t("splash.button_1")}</Link>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default RoadhouseSplashPage;
