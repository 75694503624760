function getThemeIonComponents(theme) {
    if (theme == "cocacola")
        return {
            splashPage: { "--background": "#e6e6e5" },
            menupage: { "--background": "#e6e6e5" },
        };
    if (theme == "morgana") {
        return {
            splashPage: { "--background": "#0C2835" },
            menupage: { "--background": "#0C2835" },
        };
    }
    if (theme == "sushitao") {
        return {
            splashPage: { "--background": "#0C2835" },
            menupage: { "--background": "#0C2835" },
        };
    }
    if (theme == "redcafe") {
        return {
            splashPage: { "--background": "#EEE4D2" },
            menupage: { "--background": "#EEE4D2" },
        };
    }
    if (theme == "motueka") {
        return {
            splashPage: { "--background": "black" },
            menupage: { "--background": "black" },
        };
    }
    if (theme == "camparino") {
        return {
            splashPage: { "--background": "#FFFFFF" },
            menupage: { "--background": "#FFFFFF" },
        };
    }
    if (theme == "voglia_argentina") {
        return {
            splashPage: { "--background": "#FFFFFF" },
            menupage: { "--background": "#FFFFFF" },
        };
    }
    if (theme == "pokeisland") {
        return {
            splashPage: { "--background": "#ECA8AA" },
            menupage: { "--background": "#ECA8AA" },
        };
    }
    if (theme == "ten") {
        return {
            splashPage: { "--background": "#535353" },
            menupage: { "--background": "#535353" },
        };
    }
    if (theme == "montaditos") {
        return {
            splashPage: { "--background": "#F5EFE4" },
            menupage: { "--background": "#FFFFFF" },
        };
    }
    if (theme == "barriobotanico") {
        return {
            splashPage: { "--background": "url(/assets/images/barriobotanico.png)" },
        };
    }
    if (theme == "vanquish") {
        return {
            splashPage: { "--background": "url(/assets/images/vanquish.png)" },
        };
    }
    if (theme == "letorri") {
        return {
            splashPage: { "--background": "black" },
            menupage: { "--background": "black" },
        };
    }
    if (theme == "fellini") {
        return {
            splashPage: { "--background": "url(/assets/images/fellini.png)" },
        };
    }
    if (theme == "agave") {
        return {
            splashPage: { "--background": "url(/assets/images/agave.png) no-repeat center center / cover" },
        };
    }
    if (theme == "villanichesola") {
        return {
            splashPage: { "--background": "url(/assets/images/villa_nichesola.png) no-repeat center" },
        };
    }
    if (theme == "kubic") {
        return {
            splashPage: { "--background": "url(/assets/images/kubic.png) no-repeat center" },
        };
    }
    if (theme == "allright") {
        return {
            splashPage: { "--background": "url(/assets/images/allright.png) no-repeat center center / cover" },
            menupage: { "--background": "url(/assets/images/allright_1.png) no-repeat center center / cover" },
        };
    }
    if (theme == "santo") {
        return {
            splashPage: { "--background": "url(/assets/images/santo.png) no-repeat center center / cover" },
            menupage: { "--background": "url(/assets/images/santo.png) no-repeat center center / cover" },
        };
    }
    if (theme == "redbull") {
        return {
            splashPage: { "--background": "#20447A" },
            menupage: { "--background": "#20447A" },
        };
    }
    if (theme == "sanpellegrino") {
        return {
            splashPage: { "--background": "#002B5C" },
            menupage: { "--background": "#002B5C" },
        };
    }
    if (theme == "atlanta") {
        return {
            splashPage: { "--background": "#1DABE3" },
            menupage: { "--background": "#1DABE3" },
        };
    }
    if (theme == "ultrabrasserie") {
        return {
            splashPage: { "--background": "url('/assets/ultrabrasserie/splash.png') 0 0/100% 100% no-repeat #FAF7F0" },
            menupage: { "--background": "url('/assets/ultrabrasserie/menu.png') 0 0/100% 100% no-repeat #FAF7F0" },
        };
    }
    if (theme == "roadhouse") {
        return {
            splashPage: { "--background": "#862328" },
            menupage: { "--background": "black" },
        };
    }
    else return {
        menupage: null
    }
}

function getTheme(id, setterPaletteState) {
    if (id == "9440" || id == "10158") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("motueka");
        setterPaletteState("motueka")
    }
    if (id == "6433") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("camparino");
        setterPaletteState("camparino")
    }
    if (id == "9506") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("voglia_argentina");
        setterPaletteState("voglia_argentina")
    }
    if (id == "9207") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("morgana");
        setterPaletteState("morgana")
    }
    if (id == "9521") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("il-cacciatore");
        setterPaletteState("il-cacciatore");
    }
    if (id == "9520") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("pane-vino");
        setterPaletteState("pane-vino");
    }
    if (id == "9522") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("mela-oro");
        setterPaletteState("mela-oro");
    }
    if (id == "9540") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("hotel-dolomiti");
        setterPaletteState("hotel-dolomiti");
    }
    if (id == "9564") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("mcdonald");
        setterPaletteState("mcdonald");
    }
    if (id == "9570") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("mars");
        setterPaletteState("mars");
    }
    if (id == "9616") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("adr");
        setterPaletteState("adr");
    }
    if (id == "9638") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("pokeisland");
        setterPaletteState("pokeisland");
    }
    if (id == "6474") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("radetzky");
        setterPaletteState("radetzky");
    }
    if (id == 9889 || id == 9888 || id == 9887 || id == 9886 || id == 9885 || id == 9884 || id == 9883 || id == 9882 || id == 9881 || id == 9880 || id == 9879 || id == 10104 || id == 10296 || id == 13091) {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("ten");
        setterPaletteState("ten");
    }
    if (id == "11945") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("montaditos");
        setterPaletteState("montaditos");
    }
    if (id == "9690" || id == "12045") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("barriobotanico");
        setterPaletteState("barriobotanico");
    }
    if (id == "13025") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("capannina");
        setterPaletteState("capannina");
    }
    if (id == "6568") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("vanquish");
        setterPaletteState("vanquish");
    }
    if (id == "13668" || id == "13921") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("letorri");
        setterPaletteState("letorri");
    }
    if (id == "6619") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("fellini");
        setterPaletteState("fellini");
    }
    if (id == "10182") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("agave");
        setterPaletteState("agave");
    }
    if (id == "14164") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("vanvitelli");
        setterPaletteState("vanvitelli");
    }
    if (id == "14711") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("s_pellegrino");
        setterPaletteState("s_pellegrino");
    }
    if (id == "8268") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("giappugliese");
        setterPaletteState("giappugliese");
    }
    if (id == "6757") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("villanichesola");
        setterPaletteState("villanichesola");
    }
    if (id == "6684") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("kubic");
        setterPaletteState("kubic");
    }
    if (id == "8814") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("allright");
        setterPaletteState("allright");
    }
    if (id == "14848") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("santo");
        setterPaletteState("santo");
    }
    if (id == "6870") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("atlanta");
        setterPaletteState("atlanta");
    }
    if (id == "14926") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("ultrabrasserie");
        setterPaletteState("ultrabrasserie");
    }
    if (id == "814") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("roadhouse");
        setterPaletteState("roadhouse");
    }
}

function getCustomElement(id, element, t) {
    if (element === "splash") {
        if (id === 9440 || id === 10158) {
            return "Drinklist";
        } else {
            return t("splash.button_1");
        }
    }
    if (element === "newtabs") {
        if (id === 9440 || id === 10158) {
            return "Drinklist";
        } else if (id === 9540) {
            return "BEVANDE";
        } else {
            return t("tab.button_2");
        }
    }
    if (element === "preferreddefaultscreen") {
        if (id === 9440 || id === 10158) {
            return "Premi sull'icona della stella per aggiungere un drink a questa lista. Ritroverai tutti i drink salvati in questa sezione, potendo scegliere tra i tuoi preferiti nel momento della creazione dell'ordine. Premi nuovamente sulla stella per rimuovere un preferito da questa lista.";
        } else {
            return t("preferreddefaultscreen.p_2");
        }
    }
}

function getRoadhouseCustomColor(courseName) {
    let roadhouseCustomClass;
    switch (courseName) {
        case 72862: case 78774: case 78775:
            roadhouseCustomClass = "bg-[#3D0815]";
            break;
        case 72518: case 78762:
            roadhouseCustomClass = "bg-[#4F0819]";
            break;
        case 72475: case 78737:
            roadhouseCustomClass = "bg-[#61091D]";
            break;
        case 72482: case 78743:
            roadhouseCustomClass = "bg-[#730921]";
            break;
        case 78654: case 78781:
            roadhouseCustomClass = "bg-[#9B0A2A]";
            break;
        case 78655: case 78782:
            roadhouseCustomClass = "bg-[#A80B2D]";
            break;
        case 78656: case 78783:
            roadhouseCustomClass = "bg-[#BA0B31]";
            break;
        case 72492: case 78747:
            roadhouseCustomClass = "bg-[#D3163B]";
            break;
        case 72774: case 78773:
            roadhouseCustomClass = "bg-[#D3163B]";
            break;
        case 72511: case 78755:
            roadhouseCustomClass = "bg-[#BA0B31]";
            break;
        case 72510: case 78767:
            roadhouseCustomClass = "bg-[#AA0B2E]";
            break;
        case 72522: case 78766:
            roadhouseCustomClass = "bg-[#9B0A2A]";
            break;
        case 78690: case 78784:
            roadhouseCustomClass = "bg-[#8B0A27]";
            break;
        case 72499: case 78748:
            roadhouseCustomClass = "bg-[#7C0A23]";
            break;
        default:
            roadhouseCustomClass = "";
            break;
    }

    return roadhouseCustomClass;
}

function getRoadhouseCustomBg(id) {
    // const regex = new RegExp(course.name, "gi");
    // course.name.match(regex)?.pop().replaceAll(" ", "_")
    let roadhouseCustomBg;
    switch (id) {
        // case 72862:
        //     roadhouseCustomBg = "spring";
        //     break;
        case 78719: case 78775:
            roadhouseCustomBg = "1_LUN";
            break;
        case 78720: case 78776:
            roadhouseCustomBg = "2_MART";
            break;
        case 78721: case 78777:
            roadhouseCustomBg = "3_MERC";
            break;
        case 78722: case 78778:
            roadhouseCustomBg = "4_GIOV";
            break;
        case 78723: case 78779:
            roadhouseCustomBg = "5_VEN";
            break;
        case 78724: case 78780:
            roadhouseCustomBg = "6_DOM";
            break;
        case 72518: case 78762:
            roadhouseCustomBg = "roadhouse_legend";
            break;
        case 78655: case 78782:
            roadhouseCustomBg = "best_of_grill";
            break;
        case 72510: case 78767:
            roadhouseCustomBg = "menu_pranzo_POP";
            break;
        case 72522: case 78766:
            roadhouseCustomBg = "menu_pranzo_PRIME";
            break;
        case 72481: case 78741:
            roadhouseCustomBg = "burger_creativi";
            break;
        case 72484: case 78745:
            roadhouseCustomBg = "best_of_beef";
            break;
        // case 72490:
        //     roadhouseCustomBg = "best_price";
        //     break;
        case 72492: case 78747:
            roadhouseCustomBg = "salad_e_bowl";
            break;
        case 72479: case 78740:
            roadhouseCustomBg = "burger_classici";
            break;
        case 72482: case 78743:
            roadhouseCustomBg = "house_of_ribs";
            break;
        case 72483: case 78744:
            roadhouseCustomBg = "grill_mix";
            break;
        case 72475: case 78737:
            roadhouseCustomBg = "appetizer";
            break;
        case 72480: case 78742:
            roadhouseCustomBg = "burger_sfiziosi";
            break;
        case 72774: case 78773:
            roadhouseCustomBg = "contorni";
            break;
        case 72511: case 78755:
            roadhouseCustomBg = "tentazioni_infinite_dessert";
            break;
        case 72499: case 78748:
            roadhouseCustomBg = "bibite";
            break;
        case 72503:
            roadhouseCustomBg = "birre_e_vini";
            break;
        default:
            roadhouseCustomBg = "";
            break;
    }

    return roadhouseCustomBg;
}


export default {
    getThemeIonComponents,
    getTheme,
    getCustomElement,
    getRoadhouseCustomColor,
    getRoadhouseCustomBg,
}
